import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import MemorialForm from "../components/memorialForm"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <p>
      It is with heavy hearts that we announce the recent passing of Dr. Michael
      Maynard, DVM.
    </p>
    <p>
      After a long battle with severe depression, he took his own life on
      February 14, 2021.
    </p>
    <p>
      He was a talented veterinarian and surgeon who led the surgery department
      at Greenhill Humane Society in Eugene, Oregon. He graduated from the
      School of Veterinary Medicine at the University of California Davis in
      2017.
    </p>
    <p>
      This website has been created to disseminate information to friends,
      family, and colleagues who wish to stay in touch. It is a work in
      progress.
    </p>
    <h2 style={{ fontSize: 36, fontWeight: 700, paddingTop: 20 }}>
      Memorial Service{" "}
      <div style={{ color: "darkgreen", display: "inline-block" }}>
        (Updated Tues. March 2)
      </div>
    </h2>
    <p>
      A memorial service was held on <b>Saturday, February 27</b> in{" "}
      <b>Eugene, Oregon</b>.
    </p>
    <p>
      The service was streamed live on Zoom with two-way participation by remote
      attendees. It was also recorded for posterity.
    </p>
    <p>
      <strong>
        If you are interested in receiving a copy of the video recording
      </strong>
      , please email Matt Sidor at{" "}
      <a href="mailto:mattsidor@icloud.com">mattsidor@icloud.com</a>.
    </p>
    <h2 style={{ fontSize: 36, fontWeight: 700, paddingTop: 20 }}>
      Watch this space
    </h2>
    <p>
      We're working to create an enduring virtual memorial for Dr. Mike through
      this website. Please revisit here for more updates soon.
    </p>
  </Layout>
)

export default IndexPage
